/* globals google */

import loadGoogleMapsApi from 'load-google-maps-api';

const colourScheme = [
	{
		"featureType": "all",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"saturation": 36
			},
			{
				"color": "#333333"
			},
			{
				"lightness": 40
			}
		]
	},
	{
		"featureType": "all",
		"elementType": "labels.text.stroke",
		"stylers": [
			{
				"visibility": "on"
			},
			{
				"color": "#ffffff"
			},
			{
				"lightness": 16
			}
		]
	},
	{
		"featureType": "all",
		"elementType": "labels.icon",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "administrative",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#fefefe"
			},
			{
				"lightness": 20
			}
		]
	},
	{
		"featureType": "administrative",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"color": "#fefefe"
			},
			{
				"lightness": 17
			},
			{
				"weight": 1.2
			}
		]
	},
	{
		"featureType": "landscape",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#f4f7fa"
			},
			{
				"lightness": "0"
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#dfe6ee"
			},
			{
				"lightness": "0"
			}
		]
	},
	{
		"featureType": "poi.park",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#caf9e9"
			},
			{
				"lightness": "0"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#ffffff"
			},
			{
				"lightness": 17
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"color": "#ffffff"
			},
			{
				"lightness": 29
			},
			{
				"weight": 0.2
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#ffffff"
			},
			{
				"lightness": 18
			}
		]
	},
	{
		"featureType": "road.local",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#ffffff"
			},
			{
				"lightness": 16
			}
		]
	},
	{
		"featureType": "transit",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#f2f2f2"
			},
			{
				"lightness": 19
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#465978"
			},
			{
				"lightness": "50"
			}
		]
	}
];

let location = window.location || null;

export default {
	map: null,

	init: function() {
		let element = document.getElementById('map');

		if(!element.dataset.lat || !element.dataset.lng) {
			return;
		}

		loadGoogleMapsApi({key: 'AIzaSyAc4qeSw9vL5jXPnI12LbPKI7KnoZm5Sa4' }).then(mapsApi => {
			console.log('here');

			element.map = new mapsApi.Map(element, {
				center: { lat: parseFloat(element.dataset.lat), lng: parseFloat(element.dataset.lng) },
				zoom: 18,
				disableDefaultUI: true,
				draggable: false,
				styles: colourScheme,
			});

			element.marker =  new mapsApi.Marker({
				position: { lat: parseFloat(element.dataset.lat), lng: parseFloat(element.dataset.lng) },
				map: element.map,
				icon: {
					url :        '/wp-content/themes/class/dist/assets/images/map-marker.png',
					size :       new mapsApi.Size(140, 120),
					scaledSize : new mapsApi.Size(70, 60),
					origin :     new mapsApi.Point(0, 0),
					anchor :     new mapsApi.Point(0, 30)
				}
			});
		}).catch(error => console.log(error));
	}
};
