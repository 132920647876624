import Swiper from 'swiper/dist/js/swiper.js';

export default {
	initHomeSwiper(id) {
		const homeSlider = document.getElementById(id);
		if (homeSlider) {
			const homeSliderCount = homeSlider.querySelectorAll('.swiper-slide').length;
			if(homeSliderCount > 1) {
				const swiper = new Swiper(`#${id}`, {
					centeredSlides: true,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					autoplay: {
						delay: 7000,
						disableOnInteraction: false
					},
					speed: 1000,
					loop: true,
					effect: 'fade',
					fadeEffect: {
						crossFade: true
					},
				});
			}
		}
	},
	init() {

		// Init swiper if there is more than 1 slide
		this.initHomeSwiper("home-swiper-desktop");
		this.initHomeSwiper("home-swiper-mobile");

		const postSlider = document.querySelector('.js-post-swiper');
		if(postSlider) {
			const postSwiper = new Swiper(postSlider, {
				init: false,
				centeredSlides: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				speed: 1000,
				loop: false,
				fadeEffect: {
					crossFade: true
				},
				slidesPerView: 'auto',
			});

			if(window.innerWidth <= 1200) {
				postSwiper.init();
			}

			window.addEventListener('resize', (e) => {
				if(window.innerWidth <= 1200) {
					postSwiper.init();
				}
			})
		}
	}
}
