/* globals jQuery, $ */
import "../styles/theme.scss";

import $ from "jquery";

import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import 'lazysizes';
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

import { WOW } from 'wowjs';
import Choices from 'choices.js';

import Page from './modules/Page';
import Home from './modules/Home';
import Contact from './modules/Contact';
import PoppingGrid from './modules/PoppingGrid';

import { CountUp } from 'countup.js';
import { decoFill } from "decofill";
import LineWrapper from './modules/LineWrapper';

window.Pagefield = {
	init: function() {
		this.helpers.initSvg();

		if(typeof NodeList.prototype.forEach !== 'function') {
			NodeList.prototype.forEach = Array.prototype.forEach;
		}

		//Add standard class to caldera form validation and fix errorContainer to handle class change
		// window.Parsley.on('form:init', form => {
		// 	form.options.errorTemplate = '<span></span>';
		// 	form.options.errorsWrapper = '<span class="form__validation help-block caldera_ajax_error_block"></span>';
		// 	form.options.errorWrapper = '<span></span>';
		// 	form.options.errorsContainer = field => field.$element.closest('.form__group');
		// });

		// let wrapper = new LineWrapper('.c-home-header .caption', '.title');

		//Allow user to interrupt auto-scrolling
		$(document).bind('scroll mousedown wheel keyup', function(e) {
			if(e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
				$(document.scrollingElement).stop();
			}
		});

		$('.toggle-menu').on('click', function(e) {
			e.preventDefault();
			$('body').toggleClass('side-menuOpen');
			$(this).toggleClass('active');
			$('.toggle-overlay').show();
		});

		$('.close-btn, .toggle-overlay').on('click', function(e) {
			e.preventDefault();
			$('body').removeClass('side-menuOpen');
			$('.toggle-overlay').hide();
		});

		$('.categories-dropdown').find('.select-dropdown').on('change', function(e) {
			e.preventDefault();
			var selectedOption = $(this).children('option:selected').val();
			if(selectedOption !== '' && typeof selectedOption != 'undefined') {
				window.location.href = selectedOption;
			}
		});

		jQuery(window).keydown(function(e) { if (e.keyCode == 192) debugger; });

		if(document.querySelector('.select-dropdown') !== null) {
			document.querySelectorAll('.select-dropdown').forEach(el => {
				el.choices = new Choices(el, {
					searchEnabled: false
				});
			});
		}

		let wow = new WOW(
			{
				boxClass:     'wow',      // animated element css class (default is wow)
				animateClass: 'animated', // animation css class (default is animated)
				offset:       150,          // distance to the element when triggering the animation (default is 0)
				mobile:       true,       // trigger animations on mobile devices (default is true)
				live:         false,       // act on asynchronously loaded content (default is true)
				callback:     box => {
					box.classList.add('hit');

					if(box.classList.contains('count-block')){
						let args = {
							duration: 2.6,
							easingFn: function (t, b, c, d) {
								let ts = (t /= d) * t,
									tc = ts * t;

								return b + c * (tc + -3 * ts + 3 * t);
							}
						},
						stat = box.querySelector('[data-number]');

						if(typeof stat.dataset.number !== 'undefined'){
							let number = stat.dataset.number.replace(/[^\d\.\-]/g, "");

							args.decimalPlaces = (number.indexOf('.') === -1) ? 0 : number.split('.')[1].length;

							if(typeof stat.dataset.prefix !== 'undefined'){
								args.prefix = stat.dataset.prefix;
							}
							if(typeof stat.dataset.suffix !== 'undefined'){
								args.suffix = stat.dataset.suffix;
							}

							let demo = new CountUp(stat, parseFloat(number), args);

							if (!demo.error) {
								demo.start();
							} else {
								console.warn(demo.error);
							}
						}
					}

					if(box.closest('.popping-grid') !== null){
						let grid = box.closest('.popping-grid');

						if(typeof grid.popGrid === 'undefined'){
							grid.popGrid = new PoppingGrid(grid);
						}

						// if(document.querySelector('.popping-grid') !== null) {
						// 	document.querySelectorAll('.popping-grid').forEach(el => {
						// 	});
						// }
					}
				},
				resetAnimation: false, // reset animation on end (default is true)
			}
		);
		wow.init();
		Page.init();
	},
	helpers: {
		initSvg() {
			svgxhr({
				filename: "./wp-content/themes/class/dist/sprite.svg", // URL to fetch
			});
		},
		requireAll(r) {
			r.keys().forEach(r);
		}
	},

	Page,
	Home,
	Contact,

	//Prefer creating new modules when appropriate, rather than adding functions here
	//If you need something to be globally accessible, you can assign an imported module to this namespace
	//I.e import Contact from './contact';
	//    window.Pagefield.Contact = Contact;
};

window.Pagefield.init();

window.Pagefield.helpers.requireAll(require.context('../assets/icons/', true, /\.svg$/));
