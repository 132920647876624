import Nunjucks from 'nunjucks';

class PoppingGrid{
	constructor(element){
		this.grid = typeof(element) === 'string' ? document.querySelector(element) : element;
		this.children = this.grid.querySelectorAll('.popping-wrapper');
		this.index = 0;

		this.getListener();

		if(typeof this.grid.dataset.items !== 'undefined'){
			this.items = JSON.parse(this.grid.dataset.items);
			setTimeout(() => {
				this.interval = setInterval(this.changeItem.bind(this), 1500);
			}, 4000);
		}

		document.addEventListener(this.visibilityChange, () => {
			if (document[this.hidden]) {
				clearInterval(this.interval);
			} else {
				this.interval = setInterval(this.changeItem.bind(this), 1500);
			}
		}, false);
	}

	changeItem() {
		let newObj = this.items[0],
			num    = Math.floor((Math.random() * this.children.length)),
			item   = this.children[this.index],
			tmpl   = Nunjucks.renderString(document.querySelector('#template--' + newObj.type).innerHTML, newObj);

		item.childNodes[1].classList.add('changing');
		item.childNodes[1].addEventListener('transitionend', ev => {
			if(!ev.target.classList.contains('changing')){
				return false;
			}

			this.items.push(this.items.shift());
			item.innerHTML = tmpl;
		}, { once : true });

		if(this.index >= (this.children.length - 1)){
			this.index = 0;
		} else {
			this.index++;
		}
	}

	getListener(){
		if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
			this.hidden = "hidden";
			this.visibilityChange = "visibilitychange";
		} else if (typeof document.msHidden !== "undefined") {
			this.hidden = "msHidden";
			this.visibilityChange = "msvisibilitychange";
		} else if (typeof document.webkitHidden !== "undefined") {
			this.hidden = "webkitHidden";
			this.visibilityChange = "webkitvisibilitychange";
		}
	}
}

export { PoppingGrid as default };
