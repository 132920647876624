import Swiper from 'swiper/dist/js/swiper.js';
import Accordion from "./Accordion";

export default {
    init() {
        this.initAccordions()
        this.initSwipers();
        this.initTestimonialSlider();
    },
    initAccordions() {
        if (document.querySelector('.accordion')) {
            document.querySelectorAll('.accordion').forEach(el => {
                el.accordion = new Accordion(el);
            });
        }
    },
    initSwipers() {
        let swiper_els = document.querySelectorAll('.swiper .swiper-container');
        if (swiper_els) {
            const swiper = new Swiper('.swiper .swiper-container', {
                slidesPerView: 'auto',
                spaceBetween: 20,
                centeredSlides: true,
                initialSlide: 2,
                loop: true,
                speed: 300,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 2000,
                    // disableOnInteraction: false,
                },
            });
        }
    },
    initTestimonialSlider() {
        let swiper_els = document.querySelectorAll('.swiper-container.swiper-testimonials');
        if (swiper_els) {
            const swiper = new Swiper('.swiper-container.swiper-testimonials', {
                slidesPerView: 2,
                spaceBetween: 20,
                loop: true,
                speed: 700,
                loopedSlides: 2,
                autoplay: {
                    delay: 6000,
                },
                breakpoints: {
                    768: {
                        slidesPerView: 1.1
                    },
                    992: {
                        slidesPerView: 1.5,
                        centeredSlides: true,
                    }
                }
            });
        }
    }
}
